export default defineNuxtRouteMiddleware(async (to) => {

  const targetRoute:string = to.path

  const projectRoutes:RegExp[] = [
    /^\/$/,
    /\/404/,
    /\/login/,
    /\/privacy/,
    /\/terms/,
    /\/remember/,
    /\/remember-automatic/,
    /\/pricing/,
    /\/contact/,
    /\/analytics/,
    /\/briefings(.*)/,
    /\/plannings(.*)/,
    /\/demostraciones(.*)/,
    /\/products(.*)/,
    /\/insights(.*)/,
    /\/config(.*)/
  ]

  if (!projectRoutes.some((re:RegExp) => re.test(targetRoute))) {
    return navigateTo('/404')
  }

})
