import {aliases,mdi} from 'vuetify/iconsets/mdi-svg'
import {mdiAccountMultiple,mdiDomain,mdiAccountOutline,mdiAccountEditOutline,mdiAdvertisements,mdiGoogleAnalytics,mdiApi,mdiArrowLeft,mdiArrowRightThin,mdiForumOutline,mdiCheck,mdiClose,mdiCog,mdiContentSave,mdiCurrencyEur,mdiViewDashboard,mdiViewDashboardEdit,mdiViewDashboardEditOutline,mdiDeleteOutline,mdiFileDownloadOutline,mdiPlaylistEdit,mdiLightbulbOnOutline,mdiListBox,mdiTagOutline,mdiTagEditOutline,mdiHelpCircleOutline,mdiHelp,mdiInvoiceTextOutline,mdiPlaylistCheck,mdiPercent,mdiPlusBox,mdiPencilOutline,mdiEye,mdiEyeOff,mdiFileDocumentEdit,mdiAccount,mdiRefresh,mdiLogout,mdiLock,mdiMagnify,mdiProgressCheck,mdiFlipToBack,mdiFlipToFront,mdiToggleSwitchOutline,mdiThemeLightDark} from '@mdi/js'
export const enabled = true
export const isDev = false
export function iconsConfiguration() {

  return {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      mdiAccount: mdiAccountMultiple,
mdiAccountTie: mdiDomain,
mdiAccountOutline: mdiAccountOutline,
mdiAccountEditOutline: mdiAccountEditOutline,
mdiAds: mdiAdvertisements,
mdiAnalytics: mdiGoogleAnalytics,
mdiApi: mdiApi,
mdiArrowLeft: mdiArrowLeft,
mdiArrowRight: mdiArrowRightThin,
mdiChat: mdiForumOutline,
mdiCheck: mdiCheck,
mdiClose: mdiClose,
mdiCog: mdiCog,
mdiContentSave: mdiContentSave,
mdiCurrencyEur: mdiCurrencyEur,
mdiDashboard: mdiViewDashboard,
mdiDashboardFull: mdiViewDashboardEdit,
mdiDashboardEmpty: mdiViewDashboardEditOutline,
mdiDelete: mdiDeleteOutline,
mdiDownload: mdiFileDownloadOutline,
mdiPlaylistEdit: mdiPlaylistEdit,
mdiLightbulbOnOutline: mdiLightbulbOnOutline,
mdiListbox: mdiListBox,
mdiTag: mdiTagOutline,
mdiTagEditOutline: mdiTagEditOutline,
mdiHelp: mdiHelpCircleOutline,
mdiHelpText: mdiHelp,
mdiInvoice: mdiInvoiceTextOutline,
mdiPlaylistCheck: mdiPlaylistCheck,
mdiPercent: mdiPercent,
mdiPlus: mdiPlusBox,
mdiPencil: mdiPencilOutline,
mdiEye: mdiEye,
mdiEyeOff: mdiEyeOff,
mdiFileDocumentEdit: mdiFileDocumentEdit,
mdiUser: mdiAccount,
mdiRefresh: mdiRefresh,
mdiLogout: mdiLogout,
mdiLock: mdiLock,
mdiMagnify: mdiMagnify,
mdiProgressCheck: mdiProgressCheck,
mdiProducts: mdiFlipToBack,
mdiPlans: mdiFlipToFront,
mdiSavvywords: mdiToggleSwitchOutline,
mdiTheme: mdiThemeLightDark
    },

    sets: { mdi }
  }
}

